/* By SK */

body {
    background: #F9F9FA !important;
    font-size: 0.8rem;
}

.form-control,
.input-group-text,
.form-select {
    border: 1px #98A1A8 solid;
    border-radius: 0.24rem;
}

.form-check-input {
    border: 1px #CBD0D3 solid;
    border-radius: 0.24rem;
}

.form-control,
.form-control:focus {
    font-size: 0.8rem;
    color: #333;
}

.btn-sm,
.btn-group-sm>.btn {
    padding-left: 1rem;
    padding-right: 1rem;
}

.navbar.bg-dark {
    background-color: #4190c5 !important;
    color: #ffffff;
}

.bg-label-success {
    border: 1px #49b012 solid !important;
    color: #49b012 !important;
    background-color: transparent !important;
}

.bg-label-active {
    border: 1px #4190c5 solid !important;
    color: #4190c5 !important;
    background-color: transparent !important;
}

.bg-label-pending {
    border: 1px #ff8d00 solid !important;
    color: #ff8d00 !important;
    background-color: transparent !important;
}

.bg-label-danger {
    border: 1px #ff3e1d solid !important;
    color: #ff3e1d !important;
    background-color: transparent !important;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
    color: #4190C5;
}

p {
    font-size: 0.8rem !important;
    color: #333;
}

.authentication-wrapper.authentication-basic .authentication-inner {
    max-width: 550px;
}

.btn {
    font-size: 0.8rem !important;
    box-shadow: none !important;
    font-weight: 600;
}

.bg-menu-theme .menu-inner>.menu-item.active:before {
    background: #4190c5;
}

.app-secondry-color {
    background-color: #4190c5 !important;
    box-shadow: none !important;
}

.secondry-color {
    color: #4190C5 !important;
}

.primary-color {
    color: #4190c5 !important;
}

.btn:hover,
.btn:focus {
    box-shadow: none !important;
    transform: translateY(0) !important;
}

.btn-primary {
    background-color: #33719B !important;
    border: none !important;
    margin: 0 !important;
}

.btn-light {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
    box-shadow: none;
}

.btn-light:hover {
    background-color: #ffffff;
    color: #33719B;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #53A7E0 !important;
}

.link-primary {
    color: #4190c5 !important;
    transition: all 0.23s ease 0.1s;
}

.link-primary:hover,
.link-primary:focus {
    color: #53A7E0 !important;
}

.modal .btn-close {
    box-shadow: none !important;
    transform: translate(0, 0) !important;
}

.modal .modal-header .btn-close {
    margin-top: 0 !important;
    background: transparent url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate%28-225.000000, -250.000000%29'%3E%3Cg id='Icon-Color' transform='translate%28225.000000, 250.500000%29'%3E%3Cuse fill='%23FFFFFF' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23ffffff' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") center/0.9em auto no-repeat;
}

.modal .btn-close:hover,
.modal .btn-close:focus,
.modal .btn-close:active {
    background: transparent url("data:image/svg+xml,%3Csvg width='150px' height='151px' viewBox='0 0 150 151' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='131.251657 0 74.9933705 56.25 18.7483426 0 0 18.75 56.2450278 75 0 131.25 18.7483426 150 74.9933705 93.75 131.251657 150 150 131.25 93.7549722 75 150 18.75'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='🎨-%5BSetup%5D:-Colors-&amp;-Shadows' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Artboard' transform='translate%28-225.000000, -250.000000%29'%3E%3Cg id='Icon-Color' transform='translate%28225.000000, 250.500000%29'%3E%3Cuse fill='%23dddddd' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.5' fill='%23dddddd' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") center/0.9em auto no-repeat;
}

.form-label {
    text-transform: capitalize !important;
}

.input-group:focus-within .form-control,
.input-group:focus-within .input-group-text,
.form-control:focus,
.form-select:focus {
    border-color: #66a5d0;
}

.text-primary {
    color: #4190c5 !important;
}

html:not(.layout-footer-fixed) .content-wrapper {
    margin-top: 56px !important;
}

.menu .app-brand.demo {
    height: 62px !important;
    margin-top: 0 !important;
    background-color: #4190c5 !important;
}

.menu-vertical .menu-item .menu-link {
    font-size: 0.8rem !important;
}

.bg-menu-theme .menu-inner>.menu-item.active>.menu-link {
    color: #4190c5;
    background-color: rgba(213, 223, 255, 0.16) !important;
    font-weight: 700;
}

.accordion-button,
.accordion-button:not(.collapsed) {
    color: #4190C5;
}

.form-label,
.col-form-label {
    color: #333333;
}

.card-header,
.accordion-button {
    padding: 1rem 1.2rem;
}

.accordion-body,
.card-body {
    padding: 1rem 1.2rem;
}

.accordion-header+.accordion-collapse .accordion-body {
    padding-top: 0.3rem;
}

.accordion-header {
    line-height: normal !important;
}

.bg-footer-theme {
    background-color: #DDE1E4!important;
    color: #697a8d;
}

.table,
.table:not(.table-dark) th {
    color: #333 !important;
}

.page-item.active .page-link {
    box-shadow: none !important;
}

.card .card-header+.card-body,
.card .card-header+.card-content>.card-body:first-of-type {
    padding: 1rem 1.2rem;
}

.btn-outline-primary {
    color: #4190c5;
    border-color: #4190c5;
}

.btn-outline-secondary {
    color: #333;
    border-color: #98A1A8;
    background: transparent;
}

.btn-outline-gray {
    color: rgba(67, 89, 113, 0.1);
    border-color: rgba(67, 89, 113, 0.5);
    background: transparent;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
    background-color: #53A7E0;
    border-color: #53A7E0;
}

.table-bordered td {
    padding: 0.35rem 1.2rem
}

.form-check-input:checked,
.form-check-input[type=checkbox]:indeterminate {
    background-color: #53A7E0;
    border-color: #33719B;
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #4190c5;
    border-color: #4190c5;
}

.form-switch .form-check-input:checked,
.form-switch .form-check-input[type=checkbox]:indeterminate {
    background-color: #219653;
    border-color: #219653;
}

.dropdown-menu {
    min-width: 4rem;
}

.dropdown-menu a {
    font-size: 0.8rem;
    line-height: 1.2;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #4190c5;
    background-color: #dee6eb;
}

.table> :not(caption)>*>* {
    padding: 0.4rem 0.6rem !important;
}

table:not(.table-dark) th {
    color: #566a7f;
}

.table th {
    line-height: 2;
    background-color: #F9F9FA;
    letter-spacing: inherit;
}

.app-bg-gray {
    background-color: #F9F9FA !important;
}

.app-bg-secondry {
    background-color: #4190c5 !important;
}

.app-bg-primary {
    background-color: #4190c5 !important;
}

.app-bg-primary-light {
    background-color: #E5F2FA !important;
}

.app-brand .layout-menu-toggle {
    left: 90px;
    background-color: transparent !important;
    border: none !important;
}

#layout-menu {
    margin-top: 62px;
}

.app-brand .layout-menu-toggle {
    background-color: #4190c5;
    border: 3px #69a8d1 solid;
}

.pagination-sm .page-item .page-link {
    border-radius: 0.13rem;
}

.form-check-input:checked[type=checkbox] {
    box-shadow: none;
}


/* check box sizes */

.checkbox-md {
    width: 0.7rem;
    height: 0.7rem;
}

.checkbox-lg {
    width: 1.5rem;
    height: 1.5rem;
}

.checkbox-xl {
    width: 1.4rem;
    height: 1.4rem;
}

.gridtable td {
    align-items: center;
}

.gridtable td {
    align-items: center;
}

.gridtable tr td:first-child {
    align-items: center;
    justify-content: start;
}

.gridtable td p {
    margin: 0;
    line-height: 1;
}

hr {
    margin: 0.5rem 0;
    height: 1px;
}

.cls-edit i {
    color: #4190c5;
}

.cls-del i {
    color: #ff3e1d;
}

.cls-del:hover i,
.cls-del:focus i,
.cls-edit:hover i,
.cls-edit:focus i {
    color: #fff !important;
}

.cls-scroller {
    height: 171px;
    overflow-y: scroll;
}

.app-color-dark {
    color: #333333;
}

.app-color-light {
    color: #ffffff;
}

.app-color-danger {
    color: #ff3e1d;
}

.app-color-success {
    color: #219653;
}

.form-check-label {
    color: #333333;
    font-weight: 600;
}

.app-badge {
    font-weight: 300;
}

.app-badge i {
    border-radius: 0.12rem !important;
}

.app-badge-bg-light {
    background-color: #E5F2FA;
}

.app-badge-bg-gray {
    background-color: #333333 !important;
}

.app-badge-bg-secondry {
    background-color: #33719B !important;
}

.app-badge-bg-primary {
    background-color: #4190c5 !important;
}

.app-badge-bg-danger {
    background-color: #ff3e1d !important;
}

.app-badge-bg-success {
    background-color: #219653 !important;
}

.app-btn-xs {
    line-height: 1.2;
}

.cls-client-name {
    position: absolute;
    top: -47px;
    right: -16px;
}

.cls-client-name .badge p,
.gt-icon-color {
    color: #33719B;
}

.invalid-tooltip,
.valid-tooltip {
    right: 18px;
    top: 38px;
    padding: 0 !important;
    border: none !important;
    margin: 0 !important;
    line-height: 1.2;
}

.form-check .valid-tooltip,
.form-check .invalid-tooltip {
    top: 14px;
    right: 75px;
}

.was-validated .cl-feedback-wrapper {
    position: relative;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    border-color: #49b012;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #98A1A8;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
    background-color: #49b012;
    border-color: #49b012;
}

form {
    width: 300px;
}

.intl-tel-input {
    display: table-cell;
}

.intl-tel-input .selected-flag {
    z-index: 4;
}

.intl-tel-input .country-list {
    z-index: 5;
}

.input-group .intl-tel-input .form-control {
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
}

.pointer {
    cursor: pointer;
}


/* start disabled form elements style */

.form-control:disabled,
.form-control[readonly],
.form-control:disabled:hover,
.form-control[readonly]:hover {
    background: #eceef1 !important;
    opacity: 0.7 !important;
}


/* end disabled form elements style */

.data-table-extensions-action {
    display: none;
}

.bg-light {
    background-color: #eceef1 !important;
}

.access-control-disabled {
    opacity: 0.7 !important;
    pointer-events: none !important;
}


/* 404 */

.notfound {
    height: calc(100vh - 2rem);
}

.iconBG {
    background-image: url('/src/icons/icon-404.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 300px;
    height: 300px;
}

.avatar-icon,
.mimic-icon,
.ar-icon {
    background-image: url('/src/icons/avatar-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 39px;
    height: 39px;
}

.mimic-icon {
    background-image: url('/src/icons/mimic-icon.svg');
}

.ar-icon {
    background-image: url('/src/icons/ar-icon.svg');
    width: 28px;
    height: 28px;
}


/* audit management */

.aud-temp-b {
    background-color: #53a7e026;
}

.aud-temp-y {
    background-color: #ebca5726;
}

.legnd {
    width: 15px;
    height: 15px;
}

.legnd-gt {
    background-color: #E5F2FA;
    border: #DEDAD5;
}

.legnd-na {
    background-color: #FCF7E6;
    border: #DEDAD5;
}

.legnd-at {
    background-color: #ffffff;
    border: #DEDAD5;
}

.gridtable tbody tr:not(:first-child):hover,
.gridtable tbody tr.selected {
    cursor: pointer;
    font-weight: 700;
}

.item-container .item-container {
    border-bottom: 1px solid #d9dee3;
    padding: 20px 0 20px 0;
}

.item-container .item-container:last-child {
    border-bottom: none;
}


/* define width of table. IE browsers only                 */

.gridtable table {
    float: left;
}

.gridtable thead.fixedHeader tr {
    position: relative;
}

.gridtable .tbl-audit-management tbody.scrollContent {
    display: block;
    height: 445px;
    overflow: auto;
    width: 100%
}

.gridtable .tbl-audit-planner tbody.scrollContent {
    display: block;
    height: 530px;
    overflow: auto;
    width: 100%
}

.gridtable thead.fixedHeader {
    display: table;
    overflow: auto;
    width: 100%
}

.colda {
    width: 34%;
}

.coldb {
    width: 70%;
}

.tb-mh {
    height: 630px;
}


/* Scrollbar Styling */

::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #33719B;
}

.navbar-global {
    background-color: indigo;
}

.navbar-global .navbar-brand {
    color: white;
}

.navbar-global .navbar-user>li>a {
    color: white;
}

.navbar-primary {
    background-color: #333;
    bottom: 0px;
    left: 0px;
    position: absolute;
    top: 51px;
    width: 200px;
    z-index: 8;
    overflow: hidden;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

.navbar-primary.collapsed {
    width: 60px;
}

.navbar-primary.collapsed .glyphicon {
    font-size: 22px;
}

.navbar-primary.collapsed .nav-label {
    display: none;
}

.btn-expand-collapse {
    position: absolute;
    display: block;
    left: 0px;
    bottom: 0;
    width: 100%;
    padding: 8px 0;
    border-top: solid 1px #666;
    color: grey;
    font-size: 20px;
    text-align: center;
}

.btn-expand-collapse:hover,
.btn-expand-collapse:focus {
    background-color: #222;
    color: white;
}

.btn-expand-collapse:active {
    background-color: #111;
}

.navbar-primary-menu,
.navbar-primary-menu li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.navbar-primary-menu li a {
    display: block;
    padding: 10px 18px;
    text-align: left;
    border-bottom: solid 1px #444;
    color: #ccc;
}

.navbar-primary-menu li a:hover {
    background-color: #000;
    text-decoration: none;
    color: white;
}

.navbar-primary-menu li a .glyphicon {
    margin-right: 6px;
}

.navbar-primary-menu li a:hover .glyphicon {
    color: orchid;
}

.main-content {
    margin-top: 60px;
    margin-left: 200px;
    padding: 20px;
}

.collapsed+.main-content {
    margin-left: 60px;
}


/* .layout-menu {
    left: 265px !important;
} */

.layout-mendu-expanded .layout-menu {
    width: 50px !important;
}

.layout-menu .menu-item div,
.menu-toggle::after {
    display: none;
}

.layout-menu-expanded .layout-menu .menu-item div,
.layout-menu-expanded .menu-toggle::after {
    display: block;
}

.layout-menu .menu-item div {
    animation: fade_in_hide 0.4s;
}

.layout-menu-expanded .layout-menu .menu-item div {
    animation: fade_in_show 0.4s;
}

@keyframes fade_in_show {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0.25;
    }
    50% {
        opacity: 0.5;
    }
    75% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade_in_hide {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0.75;
    }
    50% {
        opacity: 0.5;
    }
    75% {
        opacity: 0.25;
    }
    100% {
        opacity: 0;
    }
}

.layout-page {
    -moz-transition: padding-left 0.3s ease-in;
    -o-transition: padding-left 0.3s ease-in;
    -webkit-transition: padding-left 0.3s ease-in;
    transition: padding-left 0.3s ease-in;
}


/* 
.layout-menu-fixed:not(.layout-menu-collapsed) .layout-page,
.layout-menu-fixed-offcanvas:not(.layout-menu-collapsed) .layout-page {
    padding-left: 5.25rem;
}

.layout-menu-expanded .layout-page {
    padding-left: 16.25rem !important;
} */


/* .menu-vertical,
.menu-vertical .menu-block,
.menu-vertical .menu-inner>.menu-item,
.menu-vertical .menu-inner>.menu-header {
    width: 5.25rem;
} */


/* .layout-menu-expanded .menu-vertical,
.layout-menu-expanded .menu-vertical .menu-block,
.layout-menu-expanded .menu-vertical .menu-inner>.menu-item,
.layout-menu-expanded .menu-vertical .menu-inner>.menu-header {
    width: 16.25rem;
} */

.left-menu-items {
    list-style-type: none!important;
    font-size: .8rem!important;
    border-radius: 4px;
    max-width: 228px;
}

.left-menu-items:hover {
    background-color: rgba(67, 89, 113, .04);
}

.active-subment-item {
    background-color: #F8FAFF;
    font-weight: bold;
}

.left-menu-icon-color {
    color: #bebcbc;
}

.bg-menu-theme .menu-link:hover,
.bg-menu-theme .menu-link:focus,
.bg-menu-theme .menu-horizontal-prev:hover,
.bg-menu-theme .menu-horizontal-prev:focus,
.bg-menu-theme .menu-horizontal-next:hover,
.bg-menu-theme .menu-horizontal-next:focus {
    color: #566a7f;
}

.layout-menu-toggle-1 {
    background-color: #4190c5;
    position: absolute;
    left: 14rem;
    top: -48px;
    border-radius: 50%;
    border: 3px solid #f5f5f9;
    z-index: 1500;
    color: white !important;
}

.layout-page-remove-padding {
    padding-left: 0px!important;
}